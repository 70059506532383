<div class="mt-12 bg-slate-50 rounded-lg md:p-12 p-8">
	<h2 class="text-3xl font-semibold leading-[1.15]">
		Fix issues quicker with instant interactive diagnostics.
	</h2>

	<p class="mt-5 text-lg md:w-9/12">
		Send us a test email to receive a
		<mark class="font-medium">full diagnosis</mark>
		of your SPF, DKIM, and DMARC configuration,
		with clear explanations and recommendations for fixes.
	</p>

	<enhanced:img src="../../images/diagnostics.png" alt="Diagnostics result page"
								class="mt-12 w-full rounded-lg shadow-2xl" />
</div>
