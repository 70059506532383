<div class="mt-12 bg-slate-50 rounded-lg md:p-12 p-8">
	<h2 class="text-3xl font-semibold leading-[1.15] md:w-8/12">
		Easily troubleshoot delivery issues, source by source.
	</h2>

	<p class="mt-5 text-lg md:w-9/12">
		The
		<mark class="font-medium">sending source details</mark>
		page lets you thoroughly investigate SPF and DKIM failures, providing
		explanations and tips to fix common authentication issues.
	</p>

	<enhanced:img src="../../images/source-details.png" alt="Source details page"
								class="mt-12 w-full rounded-lg shadow-2xl" />
</div>
