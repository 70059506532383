<script>
	import logo from '$lib/logo.png';
	import {
		CreditCard,
		BookMarked,
		ListCheck,
		Menu,
		BookOpen,
		Rss,
		ShieldQuestion, Boxes
	} from 'lucide-svelte';
	import HeaderItem from './header-item.svelte';
	import HeaderLoginItem from './header-login-item.svelte';
	import X from 'lucide-svelte/icons/x';

	let expanded = false;
</script>

<header>
	<div class="container py-8 flex flex-row items-center justify-between flex-wrap md:gap-y-5 gap-x-4">
		<a href="/" aria-labelledby="logo">
			<img id="logo" src={logo} alt="DMARCwise" class="h-10 lg:h-11" />
		</a>

		<button class="md:hidden" on:click={() => expanded = !expanded}>
			{#if expanded}
				<X class="size-8" />
			{:else}
				<Menu class="size-8" />
			{/if}
		</button>

		<div class="flex flex-col md:flex-row items-center gap-x-2 lg:gap-x-3
								gap-y-2 md:gap-y-4 max-md:mt-8 max-md:basis-full"
				 class:max-md:hidden={!expanded}>
			<HeaderItem href="/features" icon={ListCheck} text="Features" />
			<HeaderItem href="/pricing" icon={CreditCard} text="Pricing" />
			<HeaderItem href="/msp" icon={Boxes} text="MSP" />
			<div class="max-md:hidden contents">
				<HeaderItem href="/docs" icon={BookMarked} text="Resources">
					<div class="grid grid-cols-2 gap-y-2 p-3 max-w-[600px]">
						<a class="flex gap-x-3 px-4 py-3 rounded-md hover:bg-slate-100"
							 href="/docs">
							<BookOpen class="size-5 mt-1 shrink-0" />
							<div class="flex flex-col gap-1">
								<p class="font-medium">
									Documentation
								</p>

								<p class="text-sm text-slate-500">
									Learn how to use DMARCwise and its features.
								</p>
							</div>
						</a>

						<a class="flex gap-x-3 px-4 py-3 rounded-md hover:bg-slate-100"
							 href="/learn">
							<BookMarked class="size-5 mt-1 shrink-0" />
							<div class="flex flex-col gap-1">
								<p class="font-medium">
									Learning Hub
								</p>

								<p class="text-sm text-slate-500">
									Learning resources on topics like SPF, DKIM and DMARC.
								</p>
							</div>
						</a>

						<a class="flex gap-x-3 px-4 py-3 rounded-md hover:bg-slate-100"
							 href="/blog">
							<Rss class="size-5 mt-1 shrink-0" />
							<div class="flex flex-col gap-1">
								<p class="font-medium">
									Blog
								</p>

								<p class="text-sm text-slate-500">
									Useful articles and news on domain and email security topics.
								</p>
							</div>
						</a>

						<a class="flex gap-x-3 px-4 py-3 rounded-md hover:bg-slate-100"
							 href="https://dmarc.wiki">
							<ShieldQuestion class="size-5 mt-1 shrink-0" />
							<div class="flex flex-col gap-1">
								<p class="font-medium">
									DMARC.wiki
								</p>

								<p class="text-sm text-slate-500">
									A directory of email providers and their DMARC compliance level.
								</p>
							</div>
						</a>
					</div>
				</HeaderItem>
			</div>
			<div class="md:hidden contents">
				<HeaderItem href="/docs" icon={BookOpen} text="Documentation" />
				<HeaderItem href="/learn" icon={BookMarked} text="Learning Hub" />
				<HeaderItem href="/blog " icon={Rss} text="Blog" />
			</div>
			<HeaderLoginItem />
		</div>
	</div>
</header>
