import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77')
];

export const server_loads = [];

export const dictionary = {
		"/(home)": [57,[10]],
		"/(home)/404": [58,[10]],
		"/(app)/(dashboard)/@[[org]]/clients": [14,[2,3,4]],
		"/(app)/(dashboard)/@[[org]]/digests": [15,[2,3,4]],
		"/(app)/(dashboard)/@[[org]]/domains": [16,[2,3,4]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]": [17,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/diagnoses": [18,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/diagnoses/[token]": [19,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/dns-history": [20,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/raw-reports": [21,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/raw-reports/[id]": [22,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/raw-reports/[id]/xml": [23,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/reports": [24,[2,3,4,5,6]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/reports/sources/[hostname]": [25,[2,3,4,5,6]],
		"/(app)/(dashboard)/@[[org]]/domains/[name]/settings": [26,[2,3,4,5]],
		"/(app)/(dashboard)/@[[org]]/settings": [27,[2,3,4,7]],
		"/(app)/(dashboard)/@[[org]]/settings/billing": [28,[2,3,4,7]],
		"/(app)/(dashboard)/@[[org]]/settings/billing/upgrade": [29,[2,3]],
		"/(app)/(dashboard)/@[[org]]/settings/members": [30,[2,3,4,7]],
		"/(app)/(dashboard)/@[[org]]/settings/msp": [31,[2,3,4,7]],
		"/(app)/(dashboard)/@[[org]]/settings/sso": [32,[2,3,4,7]],
		"/(app)/(dashboard)/@[[org]]/support": [33,[2,3,4]],
		"/(app)/(dashboard)/account": [34,[2,3,8]],
		"/(app)/(dashboard)/account/digests": [35,[2,3,8]],
		"/(app)/(dashboard)/account/security": [36,[2,3,8]],
		"/(app)/auth/confirm-email": [47,[2]],
		"/(app)/auth/debug": [48,[2]],
		"/(app)/auth/join": [49,[2]],
		"/(app)/auth/login": [50,[2]],
		"/(app)/auth/reset-password": [51,[2]],
		"/(app)/auth/set-new-password": [52,[2]],
		"/(app)/auth/signup": [53,[2]],
		"/(app)/auth/sso/error": [55,[2]],
		"/(app)/auth/sso/[[organizationId]]": [54,[2]],
		"/(app)/auth/unsubscribe": [56,[2]],
		"/(home)/blog": [59,[10,11]],
		"/(home)/blog/[...slug]": [60,[10,11]],
		"/(app)/(dashboard)/checkout": [37,[2,3]],
		"/(home)/check": [61,[10]],
		"/(home)/compare/dmarc-digests": [62,[10]],
		"/(home)/compare/dmarcadvisor": [63,[10]],
		"/(home)/compare/dmarcian": [64,[10]],
		"/(home)/compare/easydmarc": [65,[10]],
		"/(home)/compare/postmark-dmarc": [66,[10]],
		"/(home)/contact": [67,[10]],
		"/(home)/diagnoses/[token]": [68,[10]],
		"/(home)/docs/[...slug]": [69,[10,12]],
		"/(app)/(dashboard)/domains/[...any]": [38,[2,3]],
		"/(home)/features": [70,[10]],
		"/(app)/(dashboard)/internal": [39,[2,3,9]],
		"/(app)/(dashboard)/internal/domains": [40,[2,3,9]],
		"/(app)/(dashboard)/internal/emails/[[emailId]]": [41,[2,3,9]],
		"/(app)/(dashboard)/internal/inbound": [42,[2,3,9]],
		"/(app)/(dashboard)/internal/organizations": [43,[2,3,9]],
		"/(home)/learn": [71,[10]],
		"/(home)/learn/[...slug]": [72,[10,13]],
		"/(home)/msp": [73,[10]],
		"/(app)/(dashboard)/onboarding": [44,[2,3]],
		"/(home)/pricing": [74,[10]],
		"/(home)/privacy-policy": [75,[10]],
		"/(home)/resources": [76,[10]],
		"/(app)/(dashboard)/settings/[...any]": [45,[2,3]],
		"/(app)/(dashboard)/support/[...any]": [46,[2,3]],
		"/(home)/terms-of-service": [77,[10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';